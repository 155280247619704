<template>
  <div class="index">
    <div class="card">
      <h5 class="card-body">列表</h5>
    </div>

    <div class="list-group">
      <router-link
        :to="{ name: 'Video', query: { url: item.path, title: item.title } }"
        class="list-group-item list-group-item-action"
        v-for="item in list"
        :key="item.index"
      >
        {{ item.index }}. {{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { host, fetchCsv } from "@/data";
import { useRoute } from "vue-router";

interface VideoInfo {
  index: string;
  path: string;
  title: string;
}

export default defineComponent({
  setup() {
    const list = ref<VideoInfo[]>([]);
    const route = useRoute();

    const fetch = async () => {
      const data = await fetchCsv<VideoInfo>(`playlist/${route.params.id}.csv`);

      data.forEach(i => {
        if (!i.title) {
          const match = i.path
            .split("/")
            .pop()
            ?.match(/(.+)\.mp4/);
          if (match) i.title = match[1];
        }
        i.path = `${host}/${i.path}`;
      });

      list.value = data;
    };

    fetch();

    return { list };
  }
});
</script>
