
import { defineComponent, ref } from "vue";
import { host, fetchCsv } from "@/data";
import { useRoute } from "vue-router";

interface VideoInfo {
  index: string;
  path: string;
  title: string;
}

export default defineComponent({
  setup() {
    const list = ref<VideoInfo[]>([]);
    const route = useRoute();

    const fetch = async () => {
      const data = await fetchCsv<VideoInfo>(`playlist/${route.params.id}.csv`);

      data.forEach(i => {
        if (!i.title) {
          const match = i.path
            .split("/")
            .pop()
            ?.match(/(.+)\.mp4/);
          if (match) i.title = match[1];
        }
        i.path = `${host}/${i.path}`;
      });

      list.value = data;
    };

    fetch();

    return { list };
  }
});
